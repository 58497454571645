import React from "react"
import { Footer, Container, Content, Icon, Columns, Column } from "bloomer"


const socialLinks = [{
  id: 0,
  icon: 'fa-linkedin',
  url: 'https://www.linkedin.com/in/sethusathyan/'
}, {
  id: 1,
  icon: 'fa-twitter',
  url: 'http://twitter.com/isethu'
}, {
  id: 2,
  icon: 'fa-medium',
  url: 'https://medium.com/@sethusathyan'
},]


const MainFooter = () => {
  return (
    <Footer>
      <Container>
        {/* Built by Sethu */}
        <Columns>
          <Column isSize={"3/4"} style={{ margin: '0 auto' }}>

            <Content>
              <p>
                Made with{" "}
                <Icon hasTextColor="danger" className="fa fa-heart"></Icon> by{" "}
                <a
                  href="https://twitter.com/isethu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sethu
            </a>
              </p>
            </Content>
            {/* Built with Gatsby, Bulma, and Bloomer */}

            <Content>

              <div className="flex">
                {
                  socialLinks.map(social => (
                    <a href={social.url} target="_blank">
                      <Icon className={`fa ${social.icon}`}></Icon>
                    </a>
                  ))
                }

              </div>

            </Content>


          </Column>
        </Columns>
        




      </Container>
    </Footer>
  )
}

export default MainFooter
